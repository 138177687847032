import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TablePagination,
  ButtonGroup,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const BrokerList = () => {
  const [brokers, setBrokers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedBroker, setExpandedBroker] = useState({});
  const [newSelectedClient, setNewSelectedClient] = useState({});
  const [transactionData, setTransactionData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [brokerToDelete, setBrokerToDelete] = useState(null);
  const [, setClientToDelete] = useState(null);
  const [filterType, setFilterType] = useState('today');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate();

  const fetchBrokers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${BACKEND_URL}/v1/brokers`);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(
          `Error fetching brokers: ${response.status} ${response.statusText} - ${errorMessage}`,
        );
      }
      const data = await response.json();
      setBrokers(data);
    } catch (error) {
      console.error('Error fetching brokers:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleExpandClick = async (brokerId) => {
  //   setExpandedBroker(prevState => ({
  //     ...prevState,
  //     [brokerId]: !prevState[brokerId]
  //   }));

  //   if (!newSelectedClient[brokerId]) {
  //     try {
  //       const response = await fetch(`${BACKEND_URL}/v1/broker/${brokerId}/clients`);
  //       if (!response.ok) {
  //         const errorMessage = await response.text();
  //         throw new Error(`Error fetching clients: ${response.status} ${response.statusText} - ${errorMessage}`);
  //       }
  //       const data = await response.json();
  //       setNewSelectedClient(prevClients => ({ ...prevClients, [brokerId]: data }));
  //     } catch (error) {
  //       console.error('Error fetching clients:', error);
  //       setError(error.message);
  //     }
  //   }
  // };

  // const handleExpandClick = async (brokerId) => {
  //   setExpandedBroker(prevState => ({
  //     ...prevState,
  //     [brokerId]: !prevState[brokerId]
  //   }));

  //   if (!newSelectedClient[brokerId]) {
  //     try {
  //       const response = await fetch(`${BACKEND_URL}/v1/broker/${brokerId}/clients`);
  //       if (!response.ok) {
  //         const errorMessage = await response.text();
  //         throw new Error(`Error fetching clients: ${response.status} ${response.statusText} - ${errorMessage}`);
  //       }
  //       const data = await response.json();
  //       setNewSelectedClient(prevClients => ({ ...prevClients, [brokerId]: data }));

  //       // Fetch total gallons and commissions for each client
  //       for (const client of data) {
  //         const totalsResponse = await fetch(`${BACKEND_URL}/v1/broker/totalvolumes?clientID=${client.clientID}`);
  //         if (!totalsResponse.ok) {
  //           const errorMessage = await totalsResponse.text();
  //           throw new Error(`Error fetching totals for client ${client.clientID}: ${totalsResponse.status} ${totalsResponse.statusText} - ${errorMessage}`);
  //         }
  //         const totals = await totalsResponse.json();
  //         console.log(totals);

  //         setNewSelectedClient(prev => ({
  //           ...prev,
  //           [client.clientID]: {
  //             ...prev[client.clientID],
  //             ...totals
  //           }
  //         }));

  //         console.log(newSelectedClient);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching clients:', error);
  //       setError(error.message);
  //     }
  //   }
  // };

  const fetchClientTotals = async (client) => {
    try {
      const totalsResponse = await fetch(
        `${BACKEND_URL}/v1/broker/totalvolumes?clientID=${client.customerID}`,
      );
      if (!totalsResponse.ok) throw new Error('Failed to fetch totals');

      const totals = await totalsResponse.json();

      // Ensure the structure matches what you need
      return {
        totalgallons: totals.totalGallons || 0, // Change the keys based on your response structure
        totalcommission: totals.totalCommission || 0,
      };
    } catch (error) {
      console.error(`Error fetching totals for client ${client.clientID}:`, error);
      return { totalgallons: 0, totalcommission: 0 }; // Default values in case of error
    }
  };

  const handleExpandClick = async (brokerId) => {
    setExpandedBroker((prevState) => ({
      ...prevState,
      [brokerId]: !prevState[brokerId],
    }));

    // Check if clients for this broker have already been fetched
    if (!newSelectedClient[brokerId]) {
      try {
        // Fetch the clients for the broker
        const response = await fetch(`${BACKEND_URL}/v1/broker/${brokerId}/clients`);
        if (!response.ok) throw new Error('Failed to fetch clients');

        const clients = await response.json();

        // Set the fetched clients into state
        setNewSelectedClient((prevClients) => ({ ...prevClients, [brokerId]: clients }));

        // Fetch totals for each client
        const totalsArray = await Promise.all(clients.map((client) => fetchClientTotals(client)));

        // Update the state with totals for each client
        totalsArray.forEach((totals, index) => {
          const clientID = clients[index].clientID;
          setNewSelectedClient((prev) => ({
            ...prev,
            [clientID]: {
              ...prev[clientID],
              ...totals,
            },
          }));
        });
      } catch (error) {
        console.error('Error fetching clients:', error);
        setError(error.message);
      }
    }
  };

  const handleDelete = (broker) => {
    setBrokerToDelete(broker);
    setOpenConfirmDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await fetch(`${BACKEND_URL}/v1/broker/${brokerToDelete.id}`, { method: 'DELETE' });
      setBrokers(brokers.filter((b) => b.id !== brokerToDelete.id));
      setSnackbarMessage('Broker deleted successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting broker:', error);
      setError('Error deleting broker');
    } finally {
      setOpenConfirmDialog(false);
      setBrokerToDelete(null);
    }
  };

  const handleClientDelete = async (brokerId, clientId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/${brokerId}/clients/${clientId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setNewSelectedClient((prevClients) => ({
          ...prevClients,
          [brokerId]: prevClients[brokerId].filter((client) => client.clientID !== clientId),
        }));

        setSnackbarMessage('Client removed successfully!');
        setSnackbarOpen(true);
      } else if (response.status === 404) {
        setSnackbarMessage('Client or Broker not found.');
        setSnackbarOpen(true);
      } else {
        const errorMessage = await response.text();
        throw new Error(
          `Error deleting client: ${response.status} ${response.statusText} - ${errorMessage}`,
        );
      }
    } catch (error) {
      console.error('Error deleting client:', error);
      setError('Error deleting client');
    } finally {
      setClientToDelete(null);
    }
  };

  const handleTransactionExpandClick = async (brokerId, clientId) => {
    if (transactionData[clientId]) {
      setTransactionData((prev) => ({ ...prev, [clientId]: null }));
    } else {
      try {
        const response = await fetch(`${BACKEND_URL}/v1/transactions/${clientId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }
        const transactions = await response.json();
        setTransactionData((prev) => ({ ...prev, [clientId]: transactions }));
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError(error.message);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleManageBrokers = () => {
    navigate('/manage-brokers');
  };

  useEffect(() => {
    fetchBrokers();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedBrokers = brokers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box p={3}>
      {!loading && error && <Typography color="error">{error}</Typography>}
      {loading && !brokers.length && <CircularProgress />}

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h5" mt={4} mb={2}>
          Brokers List
        </Typography>
        <Button
          variant="contained"
          onClick={handleManageBrokers}
          sx={{
            backgroundColor: '#011f4b',
            color: 'white',
            height: 'fit-content',
            '&:hover': {
              backgroundColor: '#002a6b',
            },
            ml: 2,
          }}
        >
          Manage Brokers
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
        <Table sx={{ maxWidth: '100%' }}>
          <TableHead sx={{ maxWidth: '100%' }}>
            <TableRow sx={{ backgroundColor: '#011f4b' }}>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                BROKER ID
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                BROKER USERNAME
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                FIRST NAME
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                MIDDLE NAME
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                LAST NAME
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                PRIMARY EMAIL ID
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                SECONDARY EMAIL ID
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Address</TableCell>
              {/* <TableCell sx={{ color: 'white' }}>Commission</TableCell> */}
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}>
                ACTIONS
              </TableCell>
              <TableCell sx={{ color: 'white', padding: '4px', whiteSpace: 'nowrap' }}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ maxWidth: '100%' }}>
            {paginatedBrokers.length > 0 ? (
              paginatedBrokers.map((broker, index) => (
                <React.Fragment key={broker.id}>
                  <TableRow sx={{ backgroundColor: index % 2 === 0 ? '#e0f7fa' : '#ffffff' }}>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>{broker.id}</TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.username}
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.firstName}
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.middleName}
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.lastName}
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.primaryEmail}
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.secondaryEmail}
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      {broker.address}
                    </TableCell>
                    {/* <TableCell>{broker.commission}</TableCell> */}
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      <IconButton onClick={() => handleDelete(broker)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                      <IconButton onClick={() => handleExpandClick(broker.id)} color="primary">
                        {expandedBroker[broker.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={10}>
                      <Collapse in={expandedBroker[broker.id]} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography variant="h6" mb={2}>
                            Clients for {broker.username}
                          </Typography>
                          <Table sx={{ maxWidth: '100%' }}>
                            <TableHead sx={{ whiteSpace: 'pre-wrap' }}>
                              <TableRow sx={{ backgroundColor: '#011f4b' }}>
                                <TableCell sx={{ color: 'white', padding: '5px' }}>
                                  Client ID
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '5px' }}>
                                  Client Name
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '5px' }}>
                                  Total Monthly Volumes (Gallons)
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '5px' }}>
                                  Total Monthly Commission Volumes (Gallons)
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}>
                                  Total Volumes (Litres)
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}>
                                  Total Commission Volumes (Litres)
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}>
                                  Earned Commission (USD)
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}>
                                  Earned Commission (CAD)
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}>
                                  Pending Commissions
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}>
                                  Actions
                                </TableCell>
                                <TableCell sx={{ color: 'white', padding: '4px' }}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ whiteSpace: 'nowrap', padding: '4px' }}>
                              {newSelectedClient[broker.id] &&
                              newSelectedClient[broker.id].length > 0 ? (
                                newSelectedClient[broker.id].map((client, index) => {
                                  const transactionTotals =
                                    newSelectedClient[client.clientID] || {};
                                  const totalGallons = (
                                    transactionTotals['totalgallons'] || 0
                                  ).toFixed(3); // updated key
                                  const totalCommissionGallons = (
                                    transactionTotals['totalcommission'] || 0
                                  ).toFixed(3); // updated key

                                  return (
                                    <React.Fragment key={client.clientID}>
                                      <TableRow
                                        sx={{
                                          backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                                        }}
                                      >
                                        <TableCell>{client.clientID}</TableCell>
                                        <TableCell>{client.clientName}</TableCell>
                                        <TableCell>{totalGallons}</TableCell>
                                        <TableCell>{totalCommissionGallons}</TableCell>
                                        <TableCell>{client.totalVolumesLitres}</TableCell>
                                        <TableCell>{client.totalCommissionVolumesLitres}</TableCell>
                                        <TableCell>{client.earnedCommissionUSD}</TableCell>
                                        <TableCell>{client.earnedCommissionCAD}</TableCell>
                                        <TableCell>{client.pendingCommissions}</TableCell>
                                        <TableCell>
                                          <IconButton
                                            onClick={() =>
                                              handleClientDelete(broker.id, client.clientID)
                                            }
                                            color="error"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            onClick={() =>
                                              handleTransactionExpandClick(
                                                broker.id,
                                                client.clientID,
                                              )
                                            }
                                            color="primary"
                                          >
                                            {transactionData[client.clientID] ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )}
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>

                                      {/* Transaction Details Collapse */}
                                      <TableRow>
                                        <TableCell colSpan={11}>
                                          <Collapse
                                            in={transactionData[client.clientID]}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <CardContent>
                                              <Typography variant="h6" mb={2}>
                                                Transaction Details for {client.clientName}
                                              </Typography>
                                              <Box display="flex" justifyContent="flex-end" mb={2}>
                                                <ButtonGroup
                                                  variant="outlined"
                                                  aria-label="outlined button group"
                                                >
                                                  <Button
                                                    variant={
                                                      filterType === 'today'
                                                        ? 'contained'
                                                        : 'outlined'
                                                    }
                                                    onClick={() => setFilterType('today')}
                                                  >
                                                    Today
                                                  </Button>
                                                  <Button
                                                    variant={
                                                      filterType === 'weekly'
                                                        ? 'contained'
                                                        : 'outlined'
                                                    }
                                                    onClick={() => setFilterType('weekly')}
                                                  >
                                                    Weekly
                                                  </Button>
                                                  <Button
                                                    variant={
                                                      filterType === 'monthly'
                                                        ? 'contained'
                                                        : 'outlined'
                                                    }
                                                    onClick={() => setFilterType('monthly')}
                                                  >
                                                    Monthly
                                                  </Button>
                                                  <Button
                                                    variant={
                                                      filterType === 'yearly'
                                                        ? 'contained'
                                                        : 'outlined'
                                                    }
                                                    onClick={() => setFilterType('yearly')}
                                                  >
                                                    Yearly
                                                  </Button>
                                                </ButtonGroup>
                                              </Box>
                                              {transactionData[client.clientID] &&
                                              transactionData[client.clientID].length > 0 ? (
                                                <TransactionTable
                                                  transactions={transactionData[client.clientID]}
                                                  filterType={filterType}
                                                />
                                              ) : (
                                                <TableRow>
                                                  <TableCell colSpan={11} align="center">
                                                    No transactions available
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                            </CardContent>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={11} align="center">
                                    No clients available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  No brokers available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={brokers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this broker?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const TransactionTable = ({ transactions, filterType }) => {
  if (!transactions) return null;

  // Filter transactions based on filterType
  const filteredTransactions = transactions.filter((transaction) => {
    const transactionDate = new Date(transaction.transaction_date);
    const today = new Date();

    switch (filterType) {
      case 'today':
        return transactionDate.toDateString() === today.toDateString();
      case 'weekly': {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        return transactionDate >= oneWeekAgo;
      }
      case 'monthly':
        return (
          transactionDate.getMonth() === today.getMonth() &&
          transactionDate.getFullYear() === today.getFullYear()
        );
      case 'yearly':
        return transactionDate.getFullYear() === today.getFullYear();
      default:
        return true; // Default to show all if no filter is applied
    }
  });

  return (
    <Table sx={{ maxWidth: '100%', padding: '4px' }}>
      <TableHead sx={{ whiteSpace: 'prewrap' }}>
        <TableRow sx={{ backgroundColor: '#011f4b' }}>
          <TableCell sx={{ color: 'white' }}>Transaction Date</TableCell>
          <TableCell sx={{ color: 'white' }}>Transaction Number</TableCell>
          <TableCell sx={{ color: 'white' }}>No of Diesel Gallons</TableCell>
          <TableCell sx={{ color: 'white' }}>Discount Per D-Gallon</TableCell>
          <TableCell sx={{ color: 'white' }}>No of Reefer Gallons</TableCell>
          <TableCell sx={{ color: 'white' }}>Discount Per R-Gallon</TableCell>
          <TableCell sx={{ color: 'white' }}>Commission Per D-Gallon</TableCell>
          <TableCell sx={{ color: 'white' }}>Commission Per R-Gallon</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ padding: '4px' }}>
        {filteredTransactions.length > 0 ? (
          filteredTransactions.map((transaction, index) => (
            <TableRow
              key={transaction.transaction_number} // Ensure this is unique
              sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff' }}
            >
              <TableCell>{transaction.transaction_date}</TableCell>
              <TableCell>{transaction.transaction_number}</TableCell>
              <TableCell>{transaction.num_tractor_gallons.toFixed(3)}</TableCell>
              <TableCell>{transaction.discount_per_gallon.toFixed(3)}</TableCell>
              <TableCell>{transaction.num_reefer_gallons.toFixed(3)}</TableCell>
              <TableCell>{transaction.reefer_discount_per_gallon} </TableCell>
              <TableCell>{transaction.diesel_broker_commission}</TableCell>
              <TableCell>{transaction.reefer_broker_commission}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={8} align="center">
              No transactions available
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

// Prop validation
TransactionTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default BrokerList;
