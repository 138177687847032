import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
// import CustomToolbar from './custom-toolbar'; // Import the custom toolbar
import './UploadFile.css'; // Import the CSS file
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Date Adapter for MUI
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const UploadFile = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const buttonsFontSize = '16px';
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [fileError, setFileError] = useState('');
  const [priceQuotes, setPriceQuotes] = useState([]);
  const [priceQuotesCAD, setPriceQuotesCAD] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [effectiveDateCAD, setEffectiveDateCAD] = useState('');
  const [date, setDate] = useState('');
  const [discount, setDiscount] = useState(0);
  const [profitMargin, setProfitMargin] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');
  const [clientDetails, setClientDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('CAD'); // New state to store the selected currency
  const buttonBackground = '#011f4b';
  // const [disableCAD, setDisableCAD] = useState(false);
  // const [disableUSD, setDisableUSD] = useState(false);
  const textFieldLabelStyles = {
    style: {
      fontSize: '14px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
    },
  };

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (!loggedInUserClientId) {
        setError('Client ID not found in localStorage.');
        return;
      }

      try {
        const response = await fetch(
          `https://wex-backend.hub-ksgfuel.com/v1/client?clientID=${loggedInUserClientId}`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientDetails(data);
        console.log('clientDetails currency', data[0]?.currency);
        console.log('clientDetails', data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchClientDetails();
  }, [loggedInUserClientId]);

  // Function to format text to uppercase, handling null or undefined values
  const formatTextToUppercase = (text) => {
    return text ? text.toUpperCase() : '';
  };

  // Handle file change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileError('');
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (!file) {
      setFileError('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const uploadUrl = `${BACKEND_URL}/v1/upload/priceQuote`;

    setUploading(true);
    setError(null);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
      setFile(null); // Clear the file input after successful upload
      fetchPriceQuotes(); // Fetch price quotes after successful upload
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  // Handle file upload CAD
  const handleFileUploadCAD = async (event) => {
    event.preventDefault();

    if (!file) {
      setFileError('Please select a file first');
      return;
    }

    // Function to add one day to a date
    const addOneDay = (date) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1); // Add one day
      return newDate;
    };
    // Get the effective date in 'YYYY-MM-DD' format (America/Chicago timezone)
    const effectiveDate = addOneDay(effectiveDateCAD);
    console.log('effectiveDate', effectiveDate);

    // Format dates to 'YYYY-MM-DD'
    const options = {
      timeZone: 'America/Chicago',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    let formattedEffectiveDateCAD = new Date(effectiveDate).toLocaleDateString('en-CA', options);

    const formData = new FormData();
    formData.append('file', file);

    const uploadUrl = `https://wex-backend.hub-ksgfuel.com/v1/upload-csv?effectiveDate=${formattedEffectiveDateCAD}`;

    setUploading(true);
    setError(null);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response Data: ', data);
      setResponse(data);
      setFile(null); // Clear the file input after successful upload
      fetchPriceQuotesCAD(); // Fetch price quotes after successful upload

      // Alert customer that the file is uploaded and email will be sent soon
      alert(
        'File uploaded successfully! We are now sending email notifications to all clients. Please wait... No Action Required from your end',
      );

      // Now trigger the email notifications after successful upload
      const emailNotificationUrl = `https://wex-backend.hub-ksgfuel.com/v1/send-email-notifications`;

      console.log('Email URL Data: ', emailNotificationUrl);
      const emailResponse = await fetch(emailNotificationUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!emailResponse.ok) {
        throw new Error(`Error sending email notifications! Status: ${emailResponse.status}`);
      }
      console.log('Email Response Data: ', emailResponse);

      const emailData = await emailResponse.json();
      console.log('Email notification response:', emailData);

      // Once email is successfully sent, alert the user
      alert('Email notifications sent successfully to all clients!');
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  // Fetch discount details for the logged-in client
  const getDiscountDetailsForClient = async () => {
    let getDiscountDetailsForCustomerUrl = `${BACKEND_URL}/v1/discounts?clientID=${loggedInUserClientId}`;

    try {
      const response = await fetch(getDiscountDetailsForCustomerUrl);
      if (!response.ok) {
        console.error(
          `Error fetching data for client ${loggedInUserClientId}: ${response.statusText}`,
        );
        return;
      }

      const data = await response.json();
      console.log(`Response data for client ${loggedInUserClientId}:`, data);

      let clientDiscount = data.discount !== undefined ? data.discount : 0;
      setDiscount(clientDiscount);
      console.log('Discount:', clientDiscount);
    } catch (error) {
      console.error(`Error processing client ${loggedInUserClientId}:`, error);
    }
  };

  // Fetch Profit Margin details for the logged-in client
  const getProfitMarginDetailsForClient = async () => {
    let getProfitMarginDetailsForCustomerUrl = `https://wex-backend.hub-ksgfuel.com/v1/profit-margin?clientID=${loggedInUserClientId}`;

    try {
      const response = await fetch(getProfitMarginDetailsForCustomerUrl);
      if (!response.ok) {
        console.error(
          `Error fetching data for client ${loggedInUserClientId}: ${response.statusText}`,
        );
        return;
      }

      const data = await response.json();
      console.log(`Response data for client ${loggedInUserClientId}:`, data);

      let clientProfitMargin = data.profitMargin !== undefined ? data.profitMargin : 0.0;
      setProfitMargin(clientProfitMargin);
      console.log('Profit Margin:', clientProfitMargin);
    } catch (error) {
      console.error(`Error processing client ${loggedInUserClientId}:`, error);
    }
  };

  // Fetch price quotes and calculate savings based on discount
  const fetchPriceQuotes = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/priceQuotes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Calculate savings based on discount
      const updatedPriceQuotes = data.map((quote) => ({
        ...quote,
        savingsTotal: doNotPerformCustomerFiltration
          ? quote.savingsTotal
          : (quote.savingsTotal * (discount / 100)).toFixed(4),
        yourPrice: doNotPerformCustomerFiltration
          ? quote.yourPrice
          : (quote.discRetail - (quote.savingsTotal * (discount / 100)).toFixed(4)).toFixed(4),
      }));

      setPriceQuotes(updatedPriceQuotes);

      // Set the effective date (assuming it's the same for all quotes)
      if (data.length > 0) {
        setEffectiveDate(data[0].effectiveDate); // Adjust based on your actual data structure
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const provinceTaxRates = {
    NS: { gst: 0.15, pst: 0.0 }, // 15% GST, 0% PST
    BC: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    AB: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    SK: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    MB: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    ON: { gst: 0.13, pst: 0.0 }, // 13% GST, 0% PST
    QC: { gst: 0.05, pst: 0.0998 }, // 5% GST, 9.98% QST
  };

  // Fetch price quotes and calculate savings based on profitMargin
  const fetchPriceQuotesCAD = async () => {
    try {
      const response = await fetch(`https://wex-backend.hub-ksgfuel.com/v1/get-all-quotes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Update price quotes for DIESEL LS
      const updatedPriceQuotes = data.map((quote) => {
        if (quote.product === 'DIESEL LS') {
          const province = quote.province?.toUpperCase(); // Ensure province code is uppercase
          const taxRates = provinceTaxRates[province] || { gst: 0, pst: 0 };

          const updatedProfitMargin = parseFloat(profitMargin) || 0;
          const pricePerLiter = parseFloat(quote.pricePerLiter) || 0;

          const updatedPricePerLtr = pricePerLiter + updatedProfitMargin;
          const updatedGST = updatedPricePerLtr * taxRates.gst;
          const updatedPST = updatedPricePerLtr * taxRates.pst;
          const updatedTotalPrice = updatedPricePerLtr + updatedGST + updatedPST;

          return {
            ...quote,
            netPrice: parseFloat(quote.netPrice).toFixed(4),
            fet: parseFloat(quote.fet).toFixed(4),
            pft: parseFloat(quote.pft).toFixed(4),
            pct: parseFloat(quote.pct).toFixed(4),
            localTax: parseFloat(quote.localTax).toFixed(4),
            pricePerLiter: doNotPerformCustomerFiltration
              ? quote.pricePerLiter.toFixed(4)
              : updatedPricePerLtr.toFixed(4),
            gstHst: doNotPerformCustomerFiltration
              ? quote.gstHst.toFixed(4)
              : updatedGST.toFixed(4),
            pstQst: doNotPerformCustomerFiltration
              ? quote.pstQst.toFixed(4)
              : updatedPST.toFixed(4),
            totalPrice: doNotPerformCustomerFiltration
              ? quote.totalPrice.toFixed(4)
              : updatedTotalPrice.toFixed(4),
          };
        }
        // Ensure all other quotes have 4 decimal points for these columns
        return {
          ...quote,
          netPrice: parseFloat(quote.netPrice).toFixed(4),
          fet: parseFloat(quote.fet).toFixed(4),
          pft: parseFloat(quote.pft).toFixed(4),
          pct: parseFloat(quote.pct).toFixed(4),
          localTax: parseFloat(quote.localTax).toFixed(4),
          pricePerLiter: parseFloat(quote.pricePerLiter).toFixed(4),
          gstHst: parseFloat(quote.gstHst).toFixed(4),
          pstQst: parseFloat(quote.pstQst).toFixed(4),
          totalPrice: parseFloat(quote.totalPrice).toFixed(4),
        };
      });

      setPriceQuotesCAD(updatedPriceQuotes);

      // Set the effective date (assuming it's the same for all quotes)
      if (data.length > 0) {
        setDate(data[0].effectiveDate); // Adjust based on your actual data structure
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getDiscountDetailsForClient();
    getProfitMarginDetailsForClient();
  }, []); // Fetch discount details only once on component mount

  useEffect(() => {
    fetchPriceQuotes();
    fetchPriceQuotesCAD();
  }, [discount, profitMargin]); // Fetch price quotes when discount changes

  // Add incremental ID to each row and format text
  const formattedRows = priceQuotes.map((quote) => ({
    ...quote,
    // id: index + 1,
    truckShopName: formatTextToUppercase(quote.truckShopName),
    city: formatTextToUppercase(quote.city),
  }));

  // Filter rows based on search query
  const filteredRows = formattedRows.filter((row) => {
    return (
      row.truckShopName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.state.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const formattedRowsCAD = priceQuotesCAD.map((quote) => ({
    ...quote,
    // id: index + 1,
    name: formatTextToUppercase(quote.name),
    location: formatTextToUppercase(quote.location),
    province: formatTextToUppercase(quote.province),
  }));

  // Filter rows based on search query
  const filteredRowsCAD = formattedRowsCAD.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.province.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const columns = [
    { field: 'id', headerName: 'SITE ID', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'truckShopName',
      headerName: 'TRUCK SHOP',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    { field: 'city', headerName: 'CITY', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'state', headerName: 'STATE', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'discRetail',
      headerName: 'DISCOUNT RETAIL',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    { field: 'yourPrice', headerName: 'YOUR PRICE', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'savingsTotal',
      headerName: 'SAVINGS TOTAL',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
  ];

  const columnsCAD = [
    // { field: 'id', headerName: 'SITE ID', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'siteNumber',
      headerName: 'SITE NUMBER',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    { field: 'name', headerName: 'NAME', flex: 0.2, headerAlign: 'left', align: 'left' },
    { field: 'location', headerName: 'CITY', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'province', headerName: 'STATE', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'product', headerName: 'PRODUCT', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'netPrice', headerName: 'NET PRICE', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'fet', headerName: 'FET', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'pft', headerName: 'PFT', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'pct', headerName: 'PCT', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'localTax', headerName: 'LOCAL TAX', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'pricePerLiter',
      headerName: 'PRICE PER LITER',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    { field: 'gstHst', headerName: 'GST/HST', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'pstQst', headerName: 'PST/QST', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'totalPrice',
      headerName: 'TOTAL PRICE',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    // { field: 'priceChange', headerName: 'PRICE CHANGE', flex: 0.1, headerAlign: 'left', align: 'left' },
  ];

  // Define a function to determine the button background color based on selected currency
  const getButtonBackground = (currency) => {
    return selectedCurrency === currency ? '#FF8C00' : buttonBackground; // Highlight color for selected currency
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);

    // Re-fetch clients based on selected currency
    // getClients();

    // if (currency === 'CAD') {
    //   setDisableCAD(true);
    //   setDisableUSD(false); // Enable USD button
    //   // Disable the USD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableCAD(false);
    //   }, 10000); // 10 seconds
    // } else if (currency === 'USD') {
    //   setDisableUSD(true);
    //   setDisableCAD(false); // Enable CAD button
    //   // Disable the CAD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableUSD(false);
    //   }, 10000); // 10 seconds
    // }
  };

  // Ensure the date is valid before setting it
  const handleEffectiveDateChange = (date) => {
    // if (date instanceof Date && !isNaN(date)) {
    setEffectiveDateCAD(date); // Set the valid date
    setError(''); // Clear any previous errors
    // } else {
    //   setError('Please select a valid date.');
    // }
  };

  // Convert the date to string format (YYYY-MM-DD) for display purposes
  // const formattedEffectiveDate = effectiveDateCAD
  //   ? effectiveDateCAD.toLocaleDateString('en-US', {
  //       timeZone: 'America/Chicago',
  //       year: 'numeric',
  //       month: '2-digit',
  //       day: '2-digit',
  //     })
  //     // .split('/').reverse().join('-') // This converts MM/DD/YYYY -> YYYY-MM-DD
  //   : 'Not set'; // Default if no date is selected

  // console.log("formattedEffectiveDate", formattedEffectiveDate)

  return (
    <div>
      {/* Button Container */}
      {clientDetails && clientDetails.length > 0 && (
        <Box display="flex" mb={2} gap={2}>
          {/* Show both buttons if currency is 'CAD, USD' or empty */}
          {clientDetails[0]?.currency === 'CAD, USD' || !clientDetails[0]?.currency ? (
            <>
              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'DM Sans',
                  fontSize: buttonsFontSize,
                  backgroundColor: getButtonBackground('CAD'),
                }}
                onClick={() => handleCurrencySelect('CAD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                CAD
              </Button>

              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'DM Sans',
                  fontSize: buttonsFontSize,
                  backgroundColor: getButtonBackground('USD'),
                }}
                onClick={() => handleCurrencySelect('USD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                USD
              </Button>
            </>
          ) : null}

          {/* Show only CAD button if currency is 'CAD' */}
          {clientDetails[0]?.currency === 'CAD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'DM Sans',
                fontSize: buttonsFontSize,
                backgroundColor: getButtonBackground('CAD'),
              }}
              onClick={() => handleCurrencySelect('CAD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              CAD
            </Button>
          )}

          {/* Show only USD button if currency is 'USD' */}
          {clientDetails[0]?.currency === 'USD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'DM Sans',
                fontSize: buttonsFontSize,
                backgroundColor: getButtonBackground('USD'),
              }}
              onClick={() => handleCurrencySelect('USD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              USD
            </Button>
          )}
        </Box>
      )}
      {selectedCurrency === 'USD' && (
        <>
          {doNotPerformCustomerFiltration && (
            <div
              className="container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '100%',
              }}
            >
              <div className="card" style={{ flex: '1', minWidth: '100%' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '22px',
                    textAlign: 'center',
                    fontWeight: '800',
                    fontFamily: 'DM Sans, sans-serif',
                  }}
                  gutterBottom
                >
                  USD Upload File
                </Typography>
                <Card sx={{ minWidth: '100%', minHeight: 150 }}>
                  <CardContent>
                    <form id="upload-file-form" onSubmit={handleFileUpload}>
                      <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '2px' }}>
                        <Box sx={{ width: '250px', margin: '0 auto' }}>
                          <Typography
                            style={{ fontSize: '15px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}
                          >
                            Select PDF File
                          </Typography>
                          <TextField
                            type="file"
                            inputProps={{ accept: '.pdf' }}
                            onChange={handleFileChange}
                            fullWidth
                            helperText={fileError}
                            error={!!fileError}
                            style={{ fontSize: '18px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}
                          />
                          {fileError && (
                            <FormHelperText id="file-error-text">{fileError}</FormHelperText>
                          )}
                        </Box>
                      </Grid>

                      <Grid
                        container
                        rowSpacing={1.5}
                        columnSpacing={2.75}
                        style={{ marginTop: '2px', marginBottom: '2px' }}
                      >
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} align="center">
                          <Button
                            style={{
                              marginTop: '5px',
                              fontFamily: 'DM Sans',
                              fontSize: buttonsFontSize,
                              backgroundColor: '#011f4b',
                            }}
                            type="submit"
                            variant="contained"
                            disabled={uploading}
                            sx={{ mt: 2, mb: 5, borderRadius: 0 }}
                          >
                            {uploading ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              'Upload File'
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </form>
                    {response && (
                      <Box mt={2}>
                        <Typography
                          variant="h6"
                          sx={{ fontFamily: 'DM Sans, sans-serif', textAlign: 'center' }}
                        >
                          Upload Successful
                        </Typography>
                      </Box>
                    )}
                    {error && (
                      <Box mt={2}>
                        <Typography
                          variant="h6"
                          color="error"
                          sx={{ fontFamily: 'DM Sans, sans-serif', textAlign: 'center' }}
                        >
                          Error: {error}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          )}

          <Box mt={2}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '22px',
                textAlign: 'center',
                fontWeight: '800',
                fontFamily: 'DM Sans, sans-serif',
              }}
              gutterBottom
            >
              USD Price Quotes
            </Typography>
          </Box>

          <Box mt={2}>
            <Card>
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '18px',
                        fontFamily: 'DM Sans, sans-serif',
                        fontWeight: '800',
                      }}
                    >
                      RETAIL PRICES ARE SUBJECT TO CHANGE AT ANY TIME
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'DM Sans, sans-serif',
                        fontWeight: '800',
                      }}
                    >
                      Effective Date: {effectiveDate}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '15px' }}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <div style={{ height: 700, maxWidth: '90%', marginTop: '20px', align: 'center' }}>
                  <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={rowsPerPageOptions[0]}
                    rowsPerPageOptions={rowsPerPageOptions}
                    slots={{ toolbar: GridToolbar }} // Use the custom toolbar
                    pagination
                    paginationMode="client"
                    rowCount={filteredRows.length}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
                    }
                    rowHeight={30}
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'rgba(0, 9, 81)',
                        color: 'white',
                      },
                      '& .MuiDataGrid-cell': {
                        textAlign: 'center',
                      },
                      '& .MuiDataGrid-root': {
                        overflowX: 'auto',
                      },
                      '& .MuiDataGrid-row': {
                        height: 'auto',
                      },
                    }}
                    sortModel={[
                      {
                        field: 'state',
                        sort: 'asc',
                      },
                    ]}
                  />
                </div>
              </CardContent>
            </Card>
          </Box>
        </>
      )}

      {selectedCurrency === 'CAD' && (
        <>
          {doNotPerformCustomerFiltration && (
            <div
              className="container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '100%',
              }}
            >
              <div className="card" style={{ flex: '1', minWidth: '100%' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '22px',
                    textAlign: 'center',
                    fontWeight: '800',
                    fontFamily: 'DM Sans, sans-serif',
                  }}
                  gutterBottom
                >
                  CAD Upload File
                </Typography>
                <Card sx={{ minWidth: '100%', minHeight: 150 }}>
                  <CardContent>
                    <form id="upload-file-form" onSubmit={handleFileUploadCAD}>
                      {/* Date Picker for Effective Date */}
                      <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '2px' }}>
                        <Box sx={{ width: '250px', margin: '0 auto' }}>
                          {/* <Typography style={{ fontSize: '15px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}>
                            Effective Date
                          </Typography> */}
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Select Effective Date"
                              value={effectiveDateCAD} // Ensure this is a valid Date or null
                              onChange={handleEffectiveDateChange}
                              InputLabelProps={textFieldLabelStyles}
                              // renderInput={(params) => (
                              //   <TextField
                              //     {...params}
                              //     fullWidth
                              //     helperText={error}
                              //     error={!!error}
                              //     style={{
                              //       fontSize: '18px',
                              //       color: 'rgb(26, 33, 56)',
                              //       fontWeight: 600,
                              //     }}
                              //   />
                              // )}
                            />
                          </LocalizationProvider>
                          {error && <FormHelperText id="date-error-text">{error}</FormHelperText>}
                        </Box>
                      </Grid>

                      <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '2px' }}>
                        <Box sx={{ width: '250px', margin: '0 auto' }}>
                          <Typography
                            style={{ fontSize: '15px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}
                          >
                            Select CSV File
                          </Typography>
                          <TextField
                            type="file"
                            inputProps={{ accept: '.csv' }}
                            onChange={handleFileChange}
                            fullWidth
                            helperText={fileError}
                            error={!!fileError}
                            style={{ fontSize: '18px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}
                          />
                          {fileError && (
                            <FormHelperText id="file-error-text">{fileError}</FormHelperText>
                          )}
                        </Box>
                      </Grid>

                      <Grid
                        container
                        rowSpacing={1.5}
                        columnSpacing={2.75}
                        style={{ marginTop: '2px', marginBottom: '2px' }}
                      >
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} align="center">
                          <Button
                            style={{
                              marginTop: '5px',
                              fontFamily: 'DM Sans',
                              fontSize: buttonsFontSize,
                              backgroundColor: '#011f4b',
                            }}
                            type="submit"
                            variant="contained"
                            disabled={uploading}
                            sx={{ mt: 2, mb: 5, borderRadius: 0 }}
                          >
                            {uploading ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              'Upload File'
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </form>
                    {response && (
                      <Box mt={2}>
                        <Typography
                          variant="h6"
                          sx={{ fontFamily: 'DM Sans, sans-serif', textAlign: 'center' }}
                        >
                          Upload Successful
                        </Typography>
                      </Box>
                    )}
                    {error && (
                      <Box mt={2}>
                        <Typography
                          variant="h6"
                          color="error"
                          sx={{ fontFamily: 'DM Sans, sans-serif', textAlign: 'center' }}
                        >
                          Error: {error}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          )}

          <Box mt={2}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '22px',
                textAlign: 'center',
                fontWeight: '800',
                fontFamily: 'DM Sans, sans-serif',
              }}
              gutterBottom
            >
              CAD Price Quotes
            </Typography>
          </Box>

          <Box mt={2}>
            <Card>
              <CardContent>
                <Grid container alignItems="center">
                  {/* <Grid item xs={6}>
                  <Typography variant="h6" sx={{ fontSize: '18px', fontFamily: 'DM Sans, sans-serif', fontWeight: '800' }}>
                    RETAIL PRICES ARE SUBJECT TO CHANGE AT ANY TIME
                  </Typography>
                </Grid> */}
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'DM Sans, sans-serif',
                        fontWeight: '800',
                      }}
                    >
                      Effective Date: {date}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '15px' }}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <div style={{ height: 700, maxWidth: '95%', marginTop: '20px', align: 'center' }}>
                  <DataGrid
                    rows={filteredRowsCAD}
                    columns={columnsCAD}
                    pageSize={rowsPerPageOptions[0]}
                    rowsPerPageOptions={rowsPerPageOptions}
                    slots={{ toolbar: GridToolbar }} // Use the custom toolbar
                    pagination
                    paginationMode="client"
                    rowCount={filteredRowsCAD.length}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
                    }
                    rowHeight={30}
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'rgba(0, 9, 81)',
                        color: 'white',
                      },
                      '& .MuiDataGrid-cell': {
                        textAlign: 'center',
                      },
                      '& .MuiDataGrid-root': {
                        overflowX: 'auto',
                      },
                      '& .MuiDataGrid-row': {
                        height: 'auto',
                      },
                    }}
                    sortModel={[
                      {
                        field: 'province',
                        sort: 'asc',
                      },
                    ]}
                  />
                </div>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </div>
  );
};

export default UploadFile;
