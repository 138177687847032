import PeopleIcon from '@mui/icons-material/People';
import DiscountIcon from '@mui/icons-material/Discount';
import PersonIcon from '@mui/icons-material/Person';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ReceiptIcon from '@mui/icons-material/Receipt';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const manageMenuItems = {
  id: 'manage',
  // title: 'Manage',
  // groupIcon: ManageAccountsIcon,
  type: 'group',
  children: [
    {
      id: 'manageDashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/manage-dashboard',
      icon: ReceiptLongIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin'],
    },
    {
      id: 'manageCustomers',
      title: 'Customer',
      type: 'item',
      url: '/manage-customers',
      icon: PeopleIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin'],
    },
    {
      id: 'manageUser',
      title: 'User',
      type: 'item',
      url: '/manage-users',
      icon: PersonIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin', 'Admin'],
    },
    {
      id: 'manageDiscount',
      title: 'Discount',
      type: 'item',
      url: '/manage-discount',
      icon: DiscountIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin'],
    },
    {
      id: 'manageProfit',
      title: 'Profit Margin',
      type: 'item',
      url: '/manage-profit',
      icon: DiscountIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin'],
    },
    // {
    //   id: 'manageTransaction',
    //   title: 'Rebate',
    //   type: 'item',
    //   url: '/manage-transaction',
    //   icon: ReceiptLongIcon,
    //   breadcrumbs: false,
    //   validateRole: true,
    //   validRoles: ['Super Admin']
    // },
    {
      id: 'manageDriver',
      title: 'Driver',
      type: 'item',
      url: '/manage-driver',
      icon: ReceiptLongIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Admin'],
    },
    {
      id: 'manageUnitNumber',
      title: 'Unit Number',
      type: 'item',
      url: '/manage-unit-number',
      icon: ReceiptLongIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Admin'],
    },
    {
      id: 'locationPricing',
      title: 'Location Pricing',
      type: 'item',
      url: '/manage-location-pricing',
      icon: LocationOnIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin', 'Admin'],
    },
    // {
    //   id: 'manageBrokers',
    //   title: 'Broker',
    //   type: 'item',
    //   url: '/manage-brokers/list', // Update this path to match your route
    //   icon: PersonIcon,
    //   breadcrumbs: false,
    //   validateRole: true,
    //   validRoles: ['Super Admin', 'Broker']
    // },
    {
      id: 'manageInvoice',
      title: 'Invoice',
      type: 'item',
      url: '/manage-invoice',
      icon: ReceiptIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin'],
    },

    // {
    //     id: 'manageCard',
    //     title: 'Card',
    //     type: 'item',
    //     url: '/manage-cards',
    //     icon: CreditCardIcon,
    //     breadcrumbs: false,
    //     validateRole: true,
    //     validRoles: ['Super Admin']
    // },

    // {
    //     id: 'uploadReceipt',
    //     title: 'Upload Receipt',
    //     type: 'item',
    //     url: '/upload-receipt',
    //     icon: CloudUploadIcon,
    //     breadcrumbs: false,
    //     validateRole: true,
    //     validRoles: ['End User']
    // },
    {
      id: 'onboardClient',
      title: 'Onboard Client',
      type: 'item',
      url: '/onboard-client',
      icon: AddBusinessIcon,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin'],
    },
  ],
};

export default manageMenuItems;
