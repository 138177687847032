import * as React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { darken, lighten, styled } from '@mui/material/styles';
import { useEffect } from 'react';
import FuelCard from '../fuel-card/detailed-view';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import InfoIcon from '@mui/icons-material/Info';

const ClientDetailedView = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = React.useState(false);
  const [clientId, setClientId] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [customerName, setCustomerName] = React.useState('');
  const [isDetailedViewOpen, setIsDetailedViewOpen] = React.useState(false);
  const [cardsListView, setCardsListView] = React.useState([]);
  const [currentRowDetails, setCurrentRowDetails] = React.useState({});
  const location = useLocation();
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const gridRowStyles = { marginTop: '15px', marginBottom: '15px' };

  const cardsListViewColumns = [
    {
      field: 'id',
      headerName: 'Serial No.',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'cardNumber',
      headerName: 'Card Number',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'unitNumber',
      headerName: 'Unit Number',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'driver',
      headerName: 'Driver',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'cardStatus',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
  ];

  const NoRecordsFoundRow = [
    {
      id: '',
      cardNumber: '',
      unitNumber: 'No records found',
      driver: '',
      cardStatus: '',
    },
  ];

  useEffect(() => {
    let clientData = location.state.chosenClient;
    if (clientData) {
      let client = clientData.accountNumber;
      let customerId = clientData.customerId;
      let customerName = clientData.customerName;
      setClientId(client);
      setCustomerId(customerId);
      setCustomerName(customerName);

      let getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerId}&maxRows=1000&pageNumber=1000`;
      setLoading(true);

      fetch(getCardDetailsForCustomerUrl)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          generateListView(data.records.card);
        })
        .then(() => setLoading(false));
    }
  }, []);

  const cardStatuses = [
    {
      id: 1,
      value: 'Active',
      shortForm: 'A',
    },
    {
      id: 2,
      value: 'Blocked',
      shortForm: 'B',
    },
  ];

  const generateListView = async (cards) => {
    let cardsList = [];
    let cardSerialNo = 1;

    await cards.map((card) => {
      let cardStatus = cardStatuses.filter((status) => status.shortForm === card.cardStatus);
      let cardObject = {
        id: cardSerialNo++,
        cardNumber: card.cardNumber,
        unitNumber: card.unitNumber || card.vehicleNumber?.trim(),
        driver: `${card.firstName} ${card.lastName}` || '',
        cardStatus: cardStatus[0]?.value,
      };
      cardsList.push(cardObject);
    });

    setCardsListView(cardsList);
  };

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    // '& .MuiDataGrid-cell:hover': {
    // cursor: 'pointer',
    // },
    // '& .even': {
    // backgroundColor: 'rgb(230, 238, 242)',
    // '&:hover': {
    //     backgroundColor: getHoverBackgroundColor(
    //     'rgb(230, 238, 242)',
    //     theme.palette.mode,
    //     ),
    // }
    // },
    // '& .odd': {
    // backgroundColor: 'rgb(216, 233, 242)',
    // '&:hover': {
    //     backgroundColor: getHoverBackgroundColor(
    //     'rgb(216, 233, 242)',
    //     theme.palette.mode,
    //     ),
    // }
    // }
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(230, 238, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(230, 238, 242)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(216, 233, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(216, 233, 242)', theme.palette.mode),
      },
    },
    '& .super-app-theme--Active': {
      backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
      },
    },
    '& .super-app-theme--Unassigned': {
      backgroundColor: 'rgb(255, 241, 172)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(255, 241, 172)', theme.palette.mode),
      },
    },
    '& .super-app-theme--Blocked': {
      backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
      },
    },
  }));

  const gridCardRowClicked = (event) => {
    let rowDetails = event?.row;
    setCurrentRowDetails(rowDetails);
    setIsDetailedViewOpen(!isDetailedViewOpen);
  };

  return (
    <div>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
          <Card>
            <CardContent>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box style={{ display: 'flex' }}>
                    <WorkIcon sx={{ fontSize: 20 }} />
                    &nbsp;
                    <Typography variant="h5">CUSTOMER NAME</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="h5">{customerName}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box style={{ display: 'flex' }}>
                    <BadgeIcon sx={{ fontSize: 20 }} />
                    &nbsp;
                    <Typography variant="h5">CUSTOMER ID</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="h5">{clientId}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box style={{ display: 'flex' }}>
                    <InfoIcon sx={{ fontSize: 20 }} />
                    &nbsp;
                    <Typography variant="h5">COMDATA REFERENCE</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="h5">{customerId}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {loading ? (
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1', margin: '2px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                <Card>
                  <CardContent>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Typography variant="h5" style={fontStylesChartTypography}>
                          Card Details
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <CircularProgress color="success" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : cardsListView.length == 0 ? (
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1', margin: '2px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                <Card>
                  <CardContent>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Typography variant="h5" style={fontStylesChartTypography}>
                          Card Details
                        </Typography>
                      </Grid>
                    </Grid>
                    <div
                      style={{ height: 700, maxWidth: '95%', marginTop: '20px', align: 'center' }}
                    >
                      <StyledDataGrid
                        sx={{
                          '& .super-app-theme--header': {
                            backgroundColor: 'rgba(0, 9, 81)',
                            color: 'white',
                          },
                        }}
                        rows={NoRecordsFoundRow}
                        columns={cardsListViewColumns}
                        slots={{ toolbar: GridToolbar }}
                        rowHeight={36}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        cardsListView.length > 0 && (
          <div className="container" style={{ display: 'flex' }}>
            <div className="card" style={{ flex: '1', margin: '2px' }}>
              <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                  <Card>
                    <CardContent>
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item>
                          <Typography variant="h5" style={fontStylesChartTypography}>
                            Card Details
                          </Typography>
                        </Grid>
                      </Grid>
                      <div
                        style={{ height: 700, maxWidth: '95%', marginTop: '20px', align: 'center' }}
                      >
                        <StyledDataGrid
                          onRowClick={gridCardRowClicked}
                          sx={{
                            '& .super-app-theme--header': {
                              backgroundColor: 'rgba(0, 9, 81)',
                              color: 'white',
                            },
                          }}
                          rows={cardsListView}
                          columns={cardsListViewColumns}
                          slots={{ toolbar: GridToolbar }}
                          // getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                          getRowClassName={(params) => `super-app-theme--${params.row.cardStatus}`}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      )}

      <div>
        <Dialog fullScreen open={isDetailedViewOpen} onClose={() => setIsDetailedViewOpen(false)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setIsDetailedViewOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            {currentRowDetails && (
              <FuelCard
                inputCardNumber={currentRowDetails.cardNumber}
                inputClientId={clientId}
                inputCustomerId={customerId}
                inputCustomerName={customerName}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ClientDetailedView;
