import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { useEffect } from 'react';
// import * as React from 'react';

const DrawerContent = () => {
  // const [isLoggedIn, setIsLoggedIn] = React.useState(localStorage.getItem('isLoggedIn'));

  useEffect(() => {}, [localStorage.getItem('isLoggedIn')]);

  // window.addEventListener('storage', () => {
  //   console.log('Storage Updated')
  //   setIsLoggedIn(!isLoggedIn)
  // })

  return (
    // <div>
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      }}
    >
      {localStorage.getItem('isLoggedIn') && (
        <div style={{ backgroundColor: '#011f4b', height: '100%' }}>
          <Navigation />
          {/* <NavCard title="Help & Support" buttonTitle="Contact Us" href="https://cubensquare.com/contact-us/"/> */}
        </div>
      )}
    </SimpleBar>
    // </div>
  );
};

export default DrawerContent;
